import React, { useState } from 'react';
import config from '../config';

const Startest = (props) => {
    const {subjectid}=props;
    const user_id = localStorage.getItem("userid");
  const [rating, setRating] = useState(0);
  const apiUrl = config.backendUrl;
  const token=localStorage.getItem('user_token');

  const handleStarClick = async(starValue) => {
    setRating(starValue);

    const apiUrl1 = `${apiUrl}/submitstarrating`;
    const response = await fetch(apiUrl1, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization":`Bearer ${token}`
        },
        body: JSON.stringify({
        //   subject_id: selectedSubject,
          user_id: user_id,
          rating:starValue,
          subjectid:subjectid
        }),
      });
      const responsedata=await response.json();
      alert(responsedata.msg);


  };

  return (
    <div>
      {[1, 2, 3, 4, 5].map((starValue) => ( 
        <span
          key={starValue}
          onClick={() => handleStarClick(starValue)}
          style={{
            cursor: 'pointer',
            color: starValue <= rating ? 'gold' : 'gray',
          }}
        >
          &#9733; {/* Unicode star character */}
        </span>
      ))}
  <span>{rating}/5</span>  

    </div>

  );
};

export default Startest;
